import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'gatsby'
import slugify from 'slugify'


import Layout from '../../components/layout'
import './styles.scss'
import styles from './styles.module.scss'

export default ({ pageContext: { docs } }) => {
  const [query, setQuery] = useState('')
  const [value, setValue] = useState()

  useEffect(() => {
    setValue(slugify((query || '').toString(), { lower: true }))
  }, [query])

  return (
    <Layout title="Analisis">
      <div className="Analyses search flex flex-col items-center content-center justify-center p-12">
        <h1 className="text-4xl mb-8">Estudios</h1>
        <div className="input">
          <input
            value={query}
            onChange={e => setQuery(e.target.value)}
            type="search"
            placeholder="Buscar valores de sangre u orina"
            className="shadow-lg w-full p-4 text-lg outline-none focus:shadow-outline focus:bg-blue-100"
          />
        </div>
      </div>

      <section className={`${styles.container} py-16`}>
        <nav className="flex flex-col items-center flex-wrap justify-between">
          {docs.filter(doc => !value || doc.idx.includes(value) || doc.idx.includes(value)).map(doc => (
            <Link to={`/estudios/${doc._id}`} key={doc._id} className="w-full flex justify-between items-center flex-no-wrap rounded-lg p-4 text-xs my-2 capitalize shadow-lg hover:border-blue-500 flex items-center justify-between">
              <span className="truncate">{doc.label}</span>
              <span>${doc.price} <FontAwesomeIcon icon={faChevronRight} /></span>
            </Link>
          ))}
        </nav>
      </section>

    </Layout>
  )
}
